


































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Store } from "vuex";
import {
  dispatchUploadFile,
  dispatchGetBuildings,
  dispatchGetProject,
} from "@/store/main/actions";
import { readBuildings, readProject } from "@/store/main/getters";

@Component
export default class Upload extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: false }) public multiple!: boolean;

  public loading = true;
  public fileNames = "";
  public fullName = "";
  public shortName = "";
  public position = 0;
  public valid = true;
  public building = null;
  public uploading = false;

  @Emit()
  public files(e): FileList {
    this.fileNames = "";
    [...e.target.files].forEach((file) => (this.fileNames += file.name));
    return e.target.files;
  }

  get buildings() {
    return readBuildings(this.$store);
  }

  get project() {
    return readProject(this.$store);
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }

  public cancel() {
    this.$router.back();
  }

  get pathQuery() {
    let query = this.$router.currentRoute.query;
    if (Object.keys(query).length > 0 && query.building) {
      return query;
    }
    return;
  }

  public async mounted() {
    console.log('test',this.$router.currentRoute.query);
    await dispatchGetProject(
      this.$store,
      Number(this.$router.currentRoute.params.id)
    );
    await dispatchGetBuildings(
      this.$store,
      Number(this.$router.currentRoute.params.id)
    );
    if (this.pathQuery) {
      this.building = this.pathQuery.building as any;
    }
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.project) {
        this.uploading = true;
        let formData = new FormData();
        [...(this.$refs.fileInput as any).files].forEach((file) =>
          formData.append("file", file)
        );
        if (this.pathQuery) {
          formData.append("building_id", this.pathQuery.building as any);
        } else {
          formData.append("building_id", this.building as any);
        }
        formData.append("project_id", this.$router.currentRoute.params.id);
        formData.append("full_name", this.fullName);
        formData.append("short_name", this.shortName);
        formData.append("position", this.position.toString());
        await dispatchUploadFile(this.$store, formData);
        this.uploading = false;
        this.$router.back();
      }
    }
  }
}
